import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  borderRadius: {
    small: '0.5rem',
    normal: '1rem',
    large: '2rem',
  },

  fontFamily: {
    display: '"Satoshi", sans-serif',
    text: '"Switzer", sans-serif',
    mono: '"DM Mono", Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace',
  },

  fontSize: {
    tooltip: { desktop: '0.75rem', mobile: '0.75rem' },
    extraSmall: { desktop: '0.875rem', mobile: '0.78125rem' },
    small: { desktop: '0.9375rem', mobile: '0.84375rem' },
    body: { desktop: '1.125rem', mobile: '0.875rem' },
    large: { desktop: '1.25rem', mobile: '0.9375rem' },
    eyebrow: { desktop: '0.875rem', mobile: '0.8125rem' },
    subheading: { desktop: '1.375rem', mobile: '1.0625rem' },
    heading6: { desktop: '1.5rem', mobile: '1.125rem' },
    heading5: { desktop: '2rem', mobile: '1.375rem' },
    heading4: { desktop: '2.75rem', mobile: '1.75rem' },
    heading3: { desktop: '3rem', mobile: '1.875rem' },
    heading2: { desktop: '3.375rem', mobile: '2rem' },
    heading1: { desktop: '4rem', mobile: '2.25rem' },
  },

  fontWeights: {
    normal: 'normal',
    bold: 'bold',
  },

  lineHeights: {
    xs: '1',
    sm: '1.125',
    base: '1.25',
    lg: '1.5',
    xl: '1.75',
  },

  spacing: {
    xxs: '0.25rem',
    xs: '0.5rem',
    sm: '1rem',
    md: '2rem',
    lg: '4rem',
    xl: '6rem',
    xxl: '8rem',
    xxxl: '10rem',
    columnGap: '2rem',
    wrapperGap: '1rem',
  },

  padding: {
    xs: '1rem',
    sm: '2rem',
    md: '3rem',
    lg: '4rem',
    xl: '6rem',
    cards: {
      sm: '2rem',
      md: '4rem',
      lg: '6rem',
    },
  },

  colors: {
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#000000',
    bottleGreen: '#0A3F37',
    orinoco: '#DFFACA',
    mindaro: '#C1FD74',
    emerald: '#61D16F',
    jungleGreen: '#28A862',
    hintOfGreen: '#DFFDE3',
    mountainMeadow: '#169B84',
    transparentWhite: 'rgba(255, 255, 255, 0.8)',
  },

  borderWidths: {
    ui: '1px',
    icons: '2px',
  },

  breakpoints: {
    xs: '320px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },

  wrappers: {
    xs: '100%',
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
    xxl: '1320px',
  },

  buttonVariants: {
    primary: '#ee3932',
    white: '#FFFFFF',
    dark: '#13294e',
  },

  inputSize: {
    sm: {
      desktop: { height: '2rem', fontSize: '0.875rem', borderRadius: '1rem' },
      mobile: { height: '1.75rem', fontSize: '0.75rem', borderRadius: '0.875rem' },
    },
    md: {
      desktop: { height: '3rem', fontSize: '1rem', borderRadius: '1.5rem' },
      mobile: { height: '2.5rem', fontSize: '0.8375rem', borderRadius: '1.25rem' },
    },
    lg: {
      desktop: { height: '3.125rem', fontSize: '1.125rem', borderRadius: '1.5625rem' },
      mobile: { height: '2.5rem', fontSize: '0.875rem', borderRadius: '1.25rem' },
    },
  },

  buttonSize: {
    md: {
      desktop: { height: '4.125rem', fontSize: '1.125rem', borderRadius: '2.0625rem' },
      mobile: { height: '3rem', fontSize: '1rem', borderRadius: '1.5rem' },
    },
  },

  scales: {
    xs: '0.375',
    sm: '0.4375',
    md: '0.5',
    lg: '0.5625',
    xl: '0.8125',
    xxl: '0.9375',
  },

  shadows: {
    small: '0.125rem 0.25rem 1rem rgba(0, 0, 0, 0.125)',
    normal: '0.25rem 0.5rem 2rem rgba(0, 0, 0, 0.125)',
  },

  transitions: {
    short: '0.125s ease-in',
    normal: '0.25s ease-in',
    long: '0.5s ease-in',
  },

  zIndex: {
    navbar: 100,
    surface: 1,
    layeredGraphic: 2,
    layeredContent: 3,
    aboveLayers: 4,
  },
};
