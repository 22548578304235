import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Image from '@components/image';
import { Button } from '@styles/button.styled';

export const headerHeight = '6rem';

interface HeaderProps {
  $isActive: boolean;
  $isAlternativeColorProvided: boolean;
}

export const StyledHeader = styled.nav<HeaderProps>`
  ${({ theme, $isAlternativeColorProvided }) => css`
    display: flex;
    padding: 0.5rem 0;
    align-items: center;
    width: 100%;
    min-height: ${headerHeight};
    max-height: max-content;
    background-color: ${$isAlternativeColorProvided
      ? theme.colors.orinoco
      : theme.colors.transparentWhite};
    position: fixed;
    top: 0;
    z-index: ${theme.zIndex.navbar};
    transition: background-color ${theme.transitions.normal};
    transition-delay: 0.5;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      min-height: 6rem;
    }
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      min-height: 5rem;
    }
  `}
  ${({ $isActive, theme, $isAlternativeColorProvided }) =>
    $isActive
      ? `
      transition-delay: 0;
      background-color: ${
        $isAlternativeColorProvided ? theme.colors.orinoco : theme.colors.white
      } !important;
      `
      : ''}
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Logo = styled(Image)`
  ${({ theme }) => css`
    height: 2rem;
    width: auto;
    display: block;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: 1.5rem;
    }
  `}
`;

export const Menu = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    column-gap: 2.5rem;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const TextLinkStyles = css`
  ${({ theme }) => css`
    padding: 1.3125rem 0;
    text-decoration: none;
    color: ${theme.colors.bottleGreen};
    font-family: ${theme.fontFamily.display};
    font-weight: 500;
    font-size: ${theme.fontSize.body.desktop};
    line-height: ${theme.lineHeights.sm};

    :hover {
      text-decoration: underline;
    }

    :last-child {
      padding-right: 0;
      margin-right: 0;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin: 0 0.5rem;
    }
  `}
`;

export const StyledExternalLink = styled.a<{ $isButton: boolean }>`
  ${({ $isButton }) => css`
    ${$isButton ? Button : TextLinkStyles}
  `}
`;

export const StyledLink = styled(Link)<{ $isActive: boolean; $isButton: boolean }>`
  ${({ $isActive = false, $isButton }) => css`
    ${$isButton ? Button : TextLinkStyles}
    pointer-events: ${$isActive ? 'none' : 'auto'};
    opacity: ${$isActive ? 0.5 : 1};
  `}
`;

export const ColorLink = styled(StyledExternalLink)<{ $isAlternativeColorProvided: boolean }>`
  ${({ theme, $isAlternativeColorProvided }) => css`
    padding: 1.14rem 2rem;
    border: 2px solid ${theme.colors.mindaro};
    color: ${$isAlternativeColorProvided ? theme.colors.mindaro : theme.colors.bottleGreen};
    border-radius: 30px;
    background-color: ${$isAlternativeColorProvided
      ? theme.colors.transparent
      : theme.colors.mindaro};

    :hover {
      text-decoration: none;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding: 0.8rem 1.25rem;
      border-radius: 25px;
    }

    :last-child {
      padding-right: 2rem;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        padding-right: 1.25rem;
      }
    }
  `}
`;
