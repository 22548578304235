import React from 'react';
import { GatsbyImage, IGatsbyImageData, GatsbyImageProps } from 'gatsby-plugin-image';
import { StyledImage } from './image.styled';

export interface ImageProps {
  alt?: string;
  url?: string;
  gatsbyImageData?: IGatsbyImageData;
  className?: string;
  objectFit?: GatsbyImageProps['objectFit'];
}

const Image = ({
  gatsbyImageData,
  url = '',
  alt = '',
  className = '',
  objectFit,
}: ImageProps): JSX.Element => (
  <>
    {gatsbyImageData?.images.fallback?.srcSet ? (
      <GatsbyImage image={gatsbyImageData} alt={alt} className={className} objectFit={objectFit} />
    ) : (
      <StyledImage src={url} alt={alt} className={className} />
    )}
  </>
);

export default Image;
