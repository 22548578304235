/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProperties {
  description?: string;
  lang?: string;
  meta?: HTMLMetaElement[];
  title: string;
  faviconHref?: string;
  meta_image?: string;
  meta_description?: string;
  meta_title?: string;
}

interface IDataProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
    };
  };
}

const SEO = ({
  description = '',
  lang = 'en',
  meta = [],
  meta_image,
  meta_description,
  meta_title = '',
  title,
  faviconHref,
}: SEOProperties): JSX.Element => {
  const { site }: IDataProps = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = meta_description || description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          href: faviconHref,
        },
      ]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: meta_image !== '' && meta_image !== null ? meta_image : faviconHref,
        },
        {
          property: 'og:title',
          content:
            meta_title !== '' && meta_title != null
              ? `${site.siteMetadata.title} | ${meta_title}`
              : `${site.siteMetadata.title} | ${title}`,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: 'https://coactive.ai',
        },
        {
          name: 'twitter:title',
          content: meta_title !== '' ? meta_title : title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
