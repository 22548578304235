import React from 'react';
import { RichText } from 'prismic-reactjs';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledDescription } from './description.styled';

const Description = ({
  textColor = 'bottleGreen',
  richText,
  textSize = 'body',
  className,
}: TextComponentProps): JSX.Element => (
  <StyledDescription textSize={textSize} textColor={textColor} className={className}>
    <RichText render={richText} />
  </StyledDescription>
);

export default Description;
