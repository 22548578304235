import React from 'react';
import styled from 'styled-components';
import Title from './title';
import Eyebrow from './eyebrow';
import Subheading from './subheading';
import Description from './description';
import { TextComponentProps } from './text-props.type';

export const TextHead = styled.div<{ align?: 'center' | 'left'; maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth || '54rem'};
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme, align = 'center' }) =>
    align &&
    `
    margin: ${align === 'center' ? '0 auto' : 0};
    text-align: ${align};
    align-items: ${align};

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 45rem;
    }
  `}
`;

export interface TextProps extends TextComponentProps {
  type?: string;
}

const Text = ({
  type,
  tag,
  textSize,
  textColor,
  className = '',
  stringText,
  richText = [],
}: TextProps): JSX.Element | null => {
  switch (type) {
    case 'eyebrow':
      return <Eyebrow stringText={stringText} className={className} />;

    case 'title':
      return (
        <Title
          tag={tag}
          textSize={textSize}
          textColor={textColor}
          stringText={stringText}
          className={className}
        />
      );

    case 'subheading':
      return <Subheading textColor={textColor} richText={richText} className={className} />;

    default:
      return (
        <Description
          textSize={textSize}
          textColor={textColor}
          richText={richText}
          className={className}
        />
      );
  }
};

export default Text;
