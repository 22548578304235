import styled from 'styled-components';
import { StyledLink, StyledExternalLink } from './header.styled';

const linePxWidth = 2;

export const Line = styled.div`
  ${({ theme }) => `  
    width: 100%;
    height: ${linePxWidth}px;
    background: ${theme.colors.bottleGreen};
    border-radius: ${linePxWidth / 2}px;
    transition: all ${theme.transitions.normal};
    position: relative;
    transform-origin: 1px;
  `}
`;

export const BurgerButton = styled.div<{ $isActive: boolean }>`
  display: none;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    ${({ $isActive }) =>
      $isActive
        ? `
            ${Line} {
              :nth-child(1) {
                transform: rotate(45deg);
              }
        
              :nth-child(2) {
                opacity: 0;
                transform: translateX(20px);
              }
        
              :nth-child(3) {
                transform: rotate(-45deg);
              }
            }
        `
        : ''}
  }
`;

export const MobileContent = styled.ul<{
  isVisible?: boolean;
  headerHeight?: number;
  $isAlternativeColorProvided: boolean;
}>`
  ${({ theme, isVisible, headerHeight = 118, $isAlternativeColorProvided }) => `
    padding: 0 0 1.25rem 0;
    margin: 0;
    opacity: 0;
    top: ${headerHeight}px;
    transform: translateY(-100%);
    background: ${$isAlternativeColorProvided ? theme.colors.orinoco : theme.colors.white};
    filter: drop-shadow(${theme.shadows.normal});
    transition: top ${theme.transitions.normal}, visibility 0.01s ${theme.transitions.normal},
      opacity ${theme.transitions.normal};
    position: fixed;
    width: 100%;
    z-index: ${theme.zIndex.navbar - 1};
    visibility: hidden;
    border-bottom-left-radius: ${theme.borderRadius.normal};
    border-bottom-right-radius: ${theme.borderRadius.normal};

    ${StyledLink}, ${StyledExternalLink} {
      width: fit-content;
    }

    ${
      isVisible
        ? `
          transition: transform ${theme.transitions.normal}, visibility, opacity ${theme.transitions.normal};
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          `
        : ``
    };

    @media only screen and (min-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
`;
