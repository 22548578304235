import styled from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledSubheading = styled.span<{
  textSize: keyof typeof defaultTheme.fontSize;
  textColor: keyof typeof defaultTheme.colors;
}>`
  ${({ theme, textSize, textColor }) => `
    font-family: ${theme.fontFamily.display};
    font-size: ${theme.fontSize[textSize].desktop};
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.lg};
    color: ${theme.colors[textColor]};

    ${generateScales(
      'font-size',
      theme.fontSize[textSize].mobile,
      theme.fontSize[textSize].desktop
    )}
  `}
`;
