import React, { useState, useRef, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import { defaultTheme } from '@styles/theme.styled';
import { StyledHeader, Content, Menu, StyledLink, StyledExternalLink, Logo } from './header.styled';
import { BurgerButton, Line, MobileMenu, MobileContent } from './mobile-header.styled';

const headerIdGenerator = generateId();

gsap.registerPlugin(ScrollTrigger);

interface HeaderQueryInterface {
  prismicGeneralConfig: {
    data: {
      logo: {
        alt?: string;
        url: string;
        target: string;
      };
      navbar_links: {
        link_type: 'text' | 'button';
        link_label: string;
        link_url: {
          url: string;
          target: string;
          document: {
            uid: string;
          };
        };
      }[];
    };
  };
}

export const query = graphql`
  query HeaderQuery {
    prismicGeneralConfig {
      data {
        logo {
          url
          alt
        }
        navbar_links {
          link_type
          link_label
          link_url {
            url
            target
            document {
              ... on PrismicPage {
                uid
              }
              ... on PrismicContact {
                uid
              }
              ... on PrismicBlog {
                uid
              }
            }
          }
        }
      }
    }
  }
`;

interface HeaderProps {
  isAlternativeHeaderProvided?: boolean;
}

const Header = ({ isAlternativeHeaderProvided = false }: HeaderProps): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const location = useLocation();
  const headerRef = useRef<HTMLHeadingElement>(null);

  const {
    prismicGeneralConfig: {
      data: { logo, navbar_links },
    },
  }: HeaderQueryInterface = useStaticQuery(query);

  const navbarLinks = navbar_links.map((link) => {
    const { link_type, link_label, link_url } = link;
    const { document, url, target } = link_url;

    // is internal link
    if (document !== null) {
      const to = document.uid === 'home' ? '/' : `/${document.uid}`;
      return (
        <StyledLink
          to={to}
          key={headerIdGenerator.next().value}
          $isActive={location.pathname === to}
          $isButton={link_type === 'button'}
        >
          {link_label}
        </StyledLink>
      );
    }

    // external link
    return (
      <StyledExternalLink
        href={url}
        key={headerIdGenerator.next().value}
        target={target !== null ? target : '_self'}
        $isButton={link_type === 'button'}
      >
        {link_label}
      </StyledExternalLink>
    );
  });

  const headerHeight = headerRef?.current?.offsetHeight;

  const applyScrollTrigger = () => {
    const emphasizeNav = gsap.fromTo(
      headerRef.current,
      {
        backgroundColor: isAlternativeHeaderProvided
          ? defaultTheme.colors.orinoco
          : defaultTheme.colors.transparentWhite,
        filter: 'drop-shadow(0.25rem 0.5rem 2rem rgba(0, 0, 0, 0))',
      },
      {
        backgroundColor: isAlternativeHeaderProvided
          ? defaultTheme.colors.orinoco
          : defaultTheme.colors.white,
        filter: 'drop-shadow(0.25rem 0.5rem 2rem rgba(0, 0, 0, 0.125))',
      }
    );

    return ScrollTrigger.create({
      animation: emphasizeNav,
      trigger: 'body',
      scrub: true,
      start: `top top`,
      end: `top top-=100`,
    });
  };

  useEffect(() => {
    const scrollTriggerInstance = applyScrollTrigger();

    return () => {
      scrollTriggerInstance.kill();
    };
  }, []);

  return (
    <>
      <StyledHeader
        ref={headerRef}
        $isActive={isMenuVisible}
        $isAlternativeColorProvided={isAlternativeHeaderProvided}
      >
        <Wrapper>
          <Content id="test">
            {logo.url && (
              <Link to="/">
                <Logo url={logo.url} alt={logo?.alt || 'logo'} />
              </Link>
            )}
            <Menu>{navbarLinks}</Menu>
            <BurgerButton
              onClick={() => setIsMenuVisible(!isMenuVisible)}
              $isActive={isMenuVisible}
            >
              <Line />
              <Line />
              <Line />
            </BurgerButton>
          </Content>
        </Wrapper>
      </StyledHeader>
      <MobileContent
        isVisible={isMenuVisible}
        headerHeight={headerHeight}
        $isAlternativeColorProvided={isAlternativeHeaderProvided}
      >
        <Wrapper>
          <MobileMenu>{navbarLinks}</MobileMenu>
        </Wrapper>
      </MobileContent>
    </>
  );
};

export default Header;
