import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Wrapper from '@components/wrapper.styled';
import Image from '@components/image';
import generateId from '@helpers/generate-id';
import {
  StyledFooter,
  Content,
  LogoContainer,
  Logo,
  SocialMediasContainer,
  MetaLinksContainer,
  SocialMedia,
  Links,
  StyledLink,
  StyledExternalLink,
} from './footer.styled';

const footerIdGenerator = generateId();

interface FooterPageInterface {
  prismicGeneralConfig: {
    data: {
      legal_brand_name?: string;
      small_logo?: {
        url: string;
        alt: string;
      };
      footer_links: {
        link_label: string;
        link_url: {
          url: string;
          target: string;
          document: {
            uid: string;
          };
        };
      }[];
      body: {
        items: {
          image?: {
            alt: string;
            url: string;
          };
          community_link?: {
            url: string;
          };
        }[];
      }[];
    };
  };
}

const query = graphql`
  query FooterQuery {
    prismicGeneralConfig {
      data {
        legal_brand_name
        small_logo {
          url
          alt
        }
        footer_links {
          link_label
          link_url {
            url
            target
            document {
              ... on PrismicPage {
                uid
              }
              ... on PrismicBlog {
                uid
              }
            }
          }
        }
        body {
          ... on PrismicGeneralConfigDataBodySocialMedia {
            id
            items {
              image {
                url
                alt
              }
              community_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

interface FooterProps {
  isAlternativeFooterProvided?: boolean;
}

const Footer = ({ isAlternativeFooterProvided = false }: FooterProps): JSX.Element => {
  const {
    prismicGeneralConfig: {
      data: { legal_brand_name, footer_links, small_logo, body },
    },
  }: FooterPageInterface = useStaticQuery(query);

  const footerLinks = footer_links.map(({ link_label, link_url }) => {
    const { document, url, target } = link_url;

    // is internal link
    if (document !== null) {
      const to = document.uid === 'home' ? '/' : `/${document.uid}`;
      return (
        <StyledLink
          to={to}
          key={footerIdGenerator.next().value}
          $isAlternativeColorProvided={isAlternativeFooterProvided}
        >
          {link_label}
        </StyledLink>
      );
    }

    // external link
    return (
      <StyledExternalLink
        href={url}
        key={url}
        $isAlternativeColorProvided={isAlternativeFooterProvided}
        target={target !== null ? target : '_self'}
      >
        {link_label}
      </StyledExternalLink>
    );
  });

  const copyText = `© 2021 ${legal_brand_name || 'Coactive Systems Inc.'}`;

  return (
    <StyledFooter $isAlternativeColorProvided={isAlternativeFooterProvided}>
      <Wrapper>
        <Content>
          <LogoContainer>
            {small_logo?.url && (
              <Link to="/">
                <Logo url={small_logo.url} alt={small_logo?.alt || 'Small logo'} />
              </Link>
            )}
            <span>{copyText}</span>
          </LogoContainer>
          <Links>
            <MetaLinksContainer>{footerLinks}</MetaLinksContainer>
            <SocialMediasContainer>
              {body[0].items?.map(({ image, community_link }) => {
                if (!image?.url) {
                  return null;
                }

                return (
                  <SocialMedia
                    href={community_link?.url}
                    target="_blank"
                    rel="noreferrer"
                    key={footerIdGenerator.next().value}
                  >
                    <Image url={image?.url} alt={image?.alt} />
                  </SocialMedia>
                );
              })}
            </SocialMediasContainer>
          </Links>
        </Content>
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;
