import styled from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';

const padding = 0.75;
const triangleSize =
  (+defaultTheme.fontSize.eyebrow.desktop.slice(0, -3) + padding * 2) / Math.sqrt(2) + 0.22;

export const Container = styled.div`
  justify-content: center;
  overflow-y: hidden;
  padding: 0 1.3rem;

  ${({ theme }) => `
    margin: 0 0 ${theme.spacing.md};
  `}
`;

export const Background = styled.div`
  position: relative;
  padding: 0.7rem 0.75rem;
  width: fit-content;

  ${({ theme }) => `
    background: linear-gradient(to right, ${theme.colors.emerald}, ${theme.colors.mindaro});
  `}
`;

export const StyledEyebrow = styled.p`
  ${({ theme }) => `
    display: block;
    margin: 0;
    font-weight: ${theme.fontWeights.bold};
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.fontSize.eyebrow.desktop};
    line-height: ${theme.lineHeights.xs}rem;
    color: ${theme.colors.bottleGreen};
    text-transform: uppercase;

    ${generateScales('font-size', theme.fontSize.eyebrow.mobile, theme.fontSize.eyebrow.desktop)}
  `}
`;

export const Triangle = styled.div<{ layout: string }>`
  z-index: -1;
  position: absolute;
  top: 4px;
  width: ${triangleSize}rem;
  height: ${triangleSize}rem;
  border-radius: 0.3rem;
  overflow: hidden;
  transform: rotate(45deg);

  ${({ theme, layout }) =>
    layout === 'before'
      ? `
    left: -14px;
    background-color: ${theme.colors.emerald};
  `
      : `
    right: -14px;
    background-color: ${theme.colors.mindaro};
  `}
`;
