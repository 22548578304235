import React, { ReactNode } from 'react';
import { defaultTheme } from '@styles/theme.styled';
import { StyledSection, Content } from './section.styled';

interface SectionProperties {
  children: ReactNode;
  backgroundColor: keyof typeof defaultTheme.colors;
  backgroundImageUrl?: string;
  backgroundPosition?: string;
  isDiagonal?: boolean;
  className?: string;
}

const Section = ({
  className = '',
  children,
  backgroundColor = 'transparent',
  backgroundImageUrl,
  backgroundPosition = 'left',
  isDiagonal = false,
}: SectionProperties): JSX.Element => {
  const diagonalClass = isDiagonal ? 'section-diagonal' : '';
  const classes = `${diagonalClass} section-${backgroundColor} ${className}`;
  return (
    <StyledSection
      backgroundColor={backgroundColor}
      isDiagonal={isDiagonal}
      className={classes}
      backgroundImageUrl={backgroundImageUrl}
      backgroundPosition={backgroundPosition}
    >
      <Content>{children}</Content>
    </StyledSection>
  );
};

export default Section;
