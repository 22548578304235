import styled from 'styled-components';

const Container = styled.div`
  ${({ theme }) => `
    overflow-x: hidden;
    background-color: ${theme.colors.white};
  `}
`;

const Content = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.white};
    color: ${theme.colors.bottleGreen};
  `}
`;

export { Container, Content };
